import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'

// Notification
import handleError from '@/views/components/errorHandler'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'created_at', sortable: true, thClass: 'text-right d-none d-sm-table-cell', tdClass: 'text-right d-none d-sm-table-cell' },
    { key: 'last_login_at', sortable: true, thClass: 'text-right d-none d-sm-table-cell', tdClass: 'text-right d-none d-sm-table-cell' },
    { key: 'actions' },
  ]
  const totalUsers = ref(0)
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        payload: {
          q: searchQuery.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
        },
        companyId: ctx.$activeCompany().data.company.id,
      })
      .then(response => {
        const users = response.data

        callback(users)
        totalUsers.value = users.length
      })
      .catch(error => {
        handleError(error, toast)
      })
  }

  const fetchInvitedUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchInvitedUsers', {
        payload: {
          q: searchQuery.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
        },
        companyId: ctx.$activeCompany().data.company.id,
      })
      .then(response => {
        const users = response.data

        callback(users)
        totalUsers.value = users.length
      })
      .catch(error => {
        handleError(error, toast)
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchUsers,
    fetchInvitedUsers,
    tableColumns,
    totalUsers,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
  }
}
