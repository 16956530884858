<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-actions
        ref="loadingCard"
        no-actions
      >

        <div
          v-if="$permissions().hasPermission('create user')"
          class="m-2"
        >

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  autocomplete="off"
                />
                <b-button
                  variant="primary"
                  @click="$router.push('/users/invite')"
                >
                  <span class="text-nowrap">Invite User</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative users-table"
          :items="userList"
          responsive
          :fields="tableColumns"
          stacked="sm"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Created at -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDate }}
          </template>

          <!-- Column: Last login at -->x
          <template #cell(last_login_at)="data">
            <div
              v-if="data.item.invitation && data.item.status !== 'accepted'"
              class="d-flex align-items-center"
            >
              <b-badge
                v-if="data.item.status === 'declined'"
                pill
                variant="danger"
              >
                Declined
              </b-badge>
              <b-badge
                v-if="data.item.status === 'sent'"
                pill
                variant="info"
              >
                Invited
              </b-badge>
            </div>
            <span v-if="!data.item.invitation && data.item.last_login_at">{{ data.item.last_login_at | formatDate }}</span>
          </template>

          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.name)"
                  :to="$permissions().hasPermission('edit user') ? { name: 'user-edit', params: { userId: data.item.id } } : null"
                />
              </template>
              <b-link
                v-if="!data.item.invitation && $permissions().hasPermission('edit user')"
                :to="{ name: 'user-edit', params: { userId: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
              <span
                v-if="data.item.invitation || !$permissions().hasPermission('edit user')"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </span>
              <small
                v-if="data.item.roles"
                class="text-muted"
              >{{ data.item.roles.join(', ') }}</small>
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button-group>
              <!-- Edit user button -->
              <b-button
                v-if="$permissions().hasPermission('edit user')"
                v-b-tooltip.hover.top="'Edit user'"
                variant="primary"
                size="sm"
                :disabled="data.item.invitation"
                @click="edit(data.item.id)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="d-inline"
                />
              </b-button>
              <!-- Delete user button -->
              <b-button
                v-if="!data.item.invitation && $permissions().hasPermission('delete user')"
                v-b-tooltip.hover.top="'Delete user from the company'"
                variant="outline-danger"
                size="sm"
                @click="deleteUser(data.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline"
                />
              </b-button>
              <!-- Delete invitation button -->
              <b-button
                v-if="data.item.invitation && $permissions().hasPermission('create user')"
                v-b-tooltip.hover.top="'Delete invitation from the company'"
                variant="outline-danger"
                size="sm"
                @click="deleteInvitation(data.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline"
                />
              </b-button>
            </b-button-group>
          </template>

        </b-table>
      </b-card-actions>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, VBTooltip, BBadge, BButtonGroup,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import handleError from '@/views/components/errorHandler'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCard,
    BCardActions,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      mergeColumns: ['id', 'email', 'name', 'created_at', 'last_login_at', 'status', 'actions', 'roles'],
      existingUsers: [],
      invitedUsers: [],
    }
  },
  computed: {
    userList() {
      // Format merged list properly
      const fullList = []
      // Existing users
      Object.values(this.existingUsers).forEach(value => {
        const myObj = {}
        this.mergeColumns.forEach(column => {
          if (value[column]) {
            myObj[column] = value[column]
          } else {
            myObj[column] = null
          }
        })
        fullList.push(myObj)
      })
      // Invitations
      Object.values(this.invitedUsers).forEach(value => {
        const myObj = {}
        this.mergeColumns.forEach(column => {
          if (value[column]) {
            myObj[column] = value[column]
          } else {
            myObj[column] = null
          }
        })
        myObj.invitation = true
        fullList.push(myObj)
      })
      return fullList
    },
  },
  watch: {
    searchQuery() {
      this.fetchUsers(this, users => {
        this.existingUsers = users
      })
      this.fetchInvitedUsers(this, users => {
        this.invitedUsers = users
      })
    },
  },
  mounted() {
    this.$refs.loadingCard.showLoading = true
    this.fetchUsers(this, users => {
      this.$refs.loadingCard.showLoading = false
      this.existingUsers = users
    })
    this.fetchInvitedUsers(this, users => {
      this.$refs.loadingCard.showLoading = false
      this.invitedUsers = users
    })
  },
  methods: {
    edit(id) {
      this.$router.push({ name: 'user-edit', params: { userId: id } })
    },
    deleteUser(id) {
      this.$http.delete(`/v1/companies/${this.$activeCompany().data.company.id}/users/${id}`)
        .then(() => {
          this.fetchUsers(this, users => {
            this.existingUsers = users
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    deleteInvitation(id) {
      this.$http.delete(`/v1/companies/${this.$activeCompany().data.company.id}/invitations/${id}`)
        .then(() => {
          this.fetchInvitedUsers(this, users => {
            this.invitedUsers = users
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invitation removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchUsers,
      fetchInvitedUsers,
      tableColumns,
      totalUsers,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
    } = useUsersList()

    return {

      fetchUsers,
      fetchInvitedUsers,
      tableColumns,
      totalUsers,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
